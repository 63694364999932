<template>
  <div :class="tablediv" draggable="true" @dragstart="drag($event)">
    <span :class="tablespan" >
      {{ tablename }}
    </span><span class="table-remove" @click="removetable($event)">X</span>
  </div>
</template>
<script>
  export default {
    name: 'Ktable',
    props: {
      name: {
        type: String,
        required: true,
        default: '',
      },
      shape: {
        type: String,
        required: false,
        default: 's', // s: square, h: h>w, w: w>h, r: round
      },
      size: {
        type: Number,
        required: false,
        default: 20,
      },
    },
    data() {
      return {
        tablename: '',
        tablediv: 's',
        tablespan: 'ss',
      };
    },
    methods: {
      drag(evt) {
        this.$emit('dragstart', evt);
      },
      removetable(evt) {
        this.$emit('removetable', evt);
      },
      handleClickOutside(evt) {
        if (!this.$el.contains(evt.target)) {
          ;
        }
      },
    },
    mounted() {
      this.tablename = this.name;
      if (this.shape == 'H-Rectangle') {
        this.tablediv = 'h';
        this.tablespan = 'sh';
      } else if (this.shape == 'W-Rectangle') {
        this.tablediv = 'w';
        this.tablespan = 'sw';
      } else if (this.shape == 'Round') {
        this.tablediv = 'r';
        this.tablespan = 'sr';
      } else {
        this.tablediv = 's';
        this.tablespan = 'ss';
      }

      document.addEventListener('click', this.handleClickOutside);
    },
    destroyed() {
      document.removeEventListener('click', this.handleClickOutside);
    }
  };
</script>
<style>
  .table-remove {
    font-size: 10px;
    position: absolute;
    top: 2px;
    right: 2px;
    color: red;
  }
  .s {
    position: absolute;
    background-color: #c0c0c0;
    text-align: center;
    font-size: 20px;
    width: 60px;
    height: 60px;
    border: solid 1px black;
    border-radius: 5px;
  }
  .ss {
    display: inline-block;
    margin-top: 14px;
  }
  .w {
    position: absolute;
    background-color: #c0c0c0;
    text-align: center;
    font-size: 20px;
    width: 80px;
    height: 60px;
    border: solid 1px black;
    border-radius: 5px;
  }
  .sw {
    display: inline-block;
    margin-top: 14px;
  }
  .h {
    position: absolute;
    background-color: #c0c0c0;
    text-align: center;
    font-size: 20px;
    width: 60px;
    height: 80px;
    border: solid 1px black;
    border-radius: 5px;
  }
  .sh {
    display: inline-block;
    margin-top: 24px;
  }
  .r {
    position: absolute;
    background-color: #c0c0c0;
    text-align: center;
    font-size: 20px;
    width: 70px;
    height: 70px;
    border: solid 1px black;
    border-radius: 50%;
  }
  .sr {
    display: inline-block;
    margin-top: 18px;
  }
</style>
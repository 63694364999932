<template>
  <div>
    <b-container fluid class="content">
      <b-row v-if="floorlist">
        <b-col cols="2">
          <select class="form-control" v-model="cur_store_id" @change="change_store">
            <option v-for="st in storelist" :key="st.id" :value="st.id">
              {{ st.name }}
            </option>
          </select>
        </b-col>
        <b-col cols="1">
          <b-button
            @click="show_floor_edit"
            variant="primary"
            class="float-right"
            >{{ $t("Add") }}</b-button
          >
        </b-col>
        <b-col cols="9">
          <b-button
            v-for="floor in floorlist"
            :key="floor.id"
            @click="change_floor(floor)"
            :pressed="cur_floor.id==floor.id"
            pill
            variant="outline-primary"
            >{{ floor.name }}</b-button
          >
        </b-col>
      </b-row>
      <b-row v-if="cur_floor.id>0" class="table-content">
        <b-col cols="3">
          <b-form-group
            :label="$t('Floor / Area') + ' :'"
            label-cols="6"
            label-for="input-name"
            label-align="right"
          >
            <b-form-input
              id="input-name"
              v-model="cur_floor.name"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="5">
          <b-row>
            <b-col cols="4">
              <b-form-checkbox
                switch
                v-model="cur_floor.use_image"
                class="table-margin-top"
              >
                {{ $t("Image") }}
              </b-form-checkbox>
            </b-col>
            <b-col v-if="cur_floor.use_image" cols="8">
              <b-form-file
                id="file-image"
                accept=".jpg, .png, .gif"
                v-model="file1"
                :state="Boolean(file1)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="2">
          <b-button variant="primary" @click="update_floor">{{ $t("Save Change") }}</b-button>
        </b-col>
      </b-row>
      <b-row v-if="cur_floor.id>0">
        <span class="ml-6 mr-3">
          <div
            class="tablelistdiv"
            :style="tablelistimage"
            v-on:drop="drop($event)"
            v-on:dragover="allowDrop($event)"
          >
            <Ktable
              v-for="tb in tablelist"
              :style="{top:tb.offset_y+'px', left:tb.offset_x+'px'}"
              :key="tb.id"
              :name="tb.name"
              :shape="tb.shape"
              draggable="true"
              @dragstart="drag($event, tb)"
              @removetable="removetable($event, tb)"
            ></Ktable>
          </div>
          <label class="text-center w-100">
              Size: 1024x640
          </label>
        </span>
        <span class="ml-3 mt-4">
          <b-list-group>
            <b-list-group-item class="new-table-img">
              <b-form-group
                :label="$t('New Table')"
                label-cols="4"
                label-align="right"
              >
                <div v-bind:class="tabledivclass">
                  <span :class="tablespanclass">
                    {{ cur_table.name }}
                  </span>
                </div>
              </b-form-group>
            </b-list-group-item>
            <b-list-group-item>
              <b-form-group
                class="form-group-bottom-margin"
                :label="$t('Shape') + ' :'"
                label-cols="4"
                label-for="input-table-type"
                label-align="right"
              >
                <b-form-select
                  id="input-table-type"
                  v-model="cur_table.shape"
                  @change="new_table_type"
                >
                  <b-form-select-option
                    v-for="tb in table_shapes"
                    :key="tb"
                    :value="tb"
                    >{{ tb }}</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-list-group-item>
            <b-list-group-item>
              <b-form-group
                class="form-group-bottom-margin"
                :label="$t('Type') + ' :'"
                label-cols="4"
                label-for="input-table-type"
                label-align="right"
              >
                <b-form-select
                  id="input-table-type"
                  v-model="cur_table.ttype"
                  @change="new_table_type"
                >
                  <b-form-select-option
                    v-for="tb in table_types"
                    :key="tb"
                    :value="tb"
                    >{{ tb }}</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-list-group-item>
            <b-list-group-item>
              <b-form-group
                class="form-group-bottom-margin"
                :label="$t('Name') + ' :'"
                label-cols="4"
                label-for="input-table-name"
                label-align="right"
              >
                <b-form-input
                  id="input-table-name"
                  v-model="cur_table.name"
                  :state="cur_table.name!=''"
                ></b-form-input>
              </b-form-group>
            </b-list-group-item>
            <b-list-group-item>
              <b-form-group
                class="form-group-bottom-margin"
                :label="$t('Number of people') + ' :'"
                label-cols="4"
                label-for="input-table-people"
                label-align="right"
              >
                <b-form-input
                  id="input-table-people"
                  v-model="cur_table.guest"
                  :state="cur_table.guest!=0"
                ></b-form-input>
              </b-form-group>
            </b-list-group-item>
            <b-list-group-item class="text-center">
              <b-button variant="primary" @click="form_submit($event)">{{ $t("Add") }}</b-button>
            </b-list-group-item>
          </b-list-group>
          <!--b-list-group class="save-all-margin-top">
            <b-list-group-item>
              <b-button variant="primary">{{ $t("Save Change") }}</b-button>
            </b-list-group-item>
          </b-list-group -->
        </span>
      </b-row>
    </b-container>
    <b-modal id="floor-medal" modal-footer="false" >
      <template #modal-header="">
        <div class="text-center w-100">{{ $t('Floor / Area') }}</div>
      </template>
      <template #default="">
        <b-form inline>
          <label class="w-25" for="input-floor-name">{{ $t('Name') }}</label>
          <b-form-input
            id="input-floor-name"
            class="w-50"
            v-model="new_floor.name"
            :state="new_floor.name!=''"
          ></b-form-input>
        </b-form>
      </template>
      <template #modal-footer="">
        <b-button variant="primary" @click="add_floor()">{{ $t("Add") }}</b-button>
        <b-button variant="primary" @click="hide_floor_edit">{{ $t("Cancel") }}</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { Table, TableColumn, Button } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import Ktable from "../Ktable";
import BaseButton from "../../components/BaseButton.vue";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    flatPicker,
    Ktable,
    BaseButton,
  },
  data() {
    return {
      storelist: [],
      cur_store_id: 0,
      floorlist: [],
      cur_floor: {
        id: 0,
        name: "",
        image: "",
        status: false,
        use_image: false,
        width: 0,
        height: 0,
      },
      new_floor: {
        id: 0,
        name: "",
        image: "",
        status: true,
        use_image: false,
        width: 0,
        height: 0,
      },

      tabledivclass: "s",
      tablespanclass: "ss",
      tablelist: null,
      table_shapes: [],
      table_types: [],
      movingtable: {},
      movingevt: {},
      movingleft: 0,
      movingtop: 0,
      currpage: "list",
      cur_table: {
        id: "",
        store_id: "",
        Floor: "",
        name: "",
        shape: "Square",
        ttype: "Dine",
        guest: 4,
        sort_order: 999,
        offset_x: 0,
        offset_y: 0,
        status: 1,
        data: "",
      },
      cur_opt: null,
      new_table: {
        id: "",
        store_id: "",
        Floor: "",
        name: "",
        shape: "Square",
        ttype: "Dine",
        guest: 4,
        offset_x: 0,
        offset_y: 0,
        sort_order: 999,
        status: 1,
        data: "",
      },
      table_status: [],
      search: {
        store_id: "",
        floor_id: "",
        status: "",
      },

      file1: null,

      token: "",
      user_id: 0,
    };
  },
  methods: {
    change_store() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("store_id", this.cur_store_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Floor/search",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.floorlist = rt.data.floors;
              if (rt.data.floors.length>0) {
                that.cur_floor = that.floorlist[0];
                that.change_floor_1_true();
                that.get_cur_table_list();
              } else {
                that.cur_floor = that.new_floor;
                that.tablelist = null;
              }
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            this.$router.push("/weblogin");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/weblogin");
        });
    },
    change_floor_1_true() {
      if (this.cur_floor.status == 1) {
        this.cur_floor.status = true;
      } else {
        this.cur_floor.status = false;
      }
      if (this.cur_floor.use_image == 1) {
        this.cur_floor.use_image = true;
      } else {
        this.cur_floor.use_image = false;
      }
    },
    change_floor(fl) {
      this.cur_floor = fl;
      this.change_floor_1_true()
      this.get_cur_table_list();
    },
    show_floor_edit() {
      this.$bvModal.show('floor-medal');
    },
    hide_floor_edit() {
      this.$bvModal.hide('floor-medal');
    },
    async add_floor() {
      if (this.new_floor.name != '') {
        var bodyFormData = new FormData();
        bodyFormData.append("token", this.token);
        bodyFormData.append("user_id", this.user_id);
        bodyFormData.append("opt", "add");
        bodyFormData.append("store_id", this.cur_store_id);
        bodyFormData.append("name", this.new_floor.name);
        bodyFormData.append("image", '');
        bodyFormData.append("use_image", 0);
        bodyFormData.append("width", 1024);
        bodyFormData.append("height", 640);
        bodyFormData.append("status", 1);
        bodyFormData.append("allfloor", 1);
        this.hide_floor_edit();

        var that = this;
        axios({
          method: "post",
          url: "/Api/Web/Floor/edit",
          data: bodyFormData,
        })
          .then(function (response) {
            if (response.status == "200") {
              var rt = response.data;
              if (rt.status == 0) {
                // that.$router.push("/store");
                // that.$router.go();
                if (rt.data.floors) {
                  that.floorlist = rt.data.floors;
                }
                if (rt.data.floor.status == 1) {
                  rt.data.floor.status = true;
                } else {
                  rt.data.floor.status = false;
                }
                if (rt.data.floor.use_image == 1) {
                  rt.data.floor.use_image = true;
                } else {
                  rt.data.floor.use_image = false;
                }
                that.cur_floor = rt.data.floor;
                that.get_cur_table_list();
              } else if (rt.message != undefined) {
                alert(rt.message);
              }
            }
          })
          .catch(function (error) {
            // console.log(error);
            that.$router.push("/weblogin");
          });
      }
    },
    async update_floor() {
      if (this.cur_floor && (this.cur_floor.id != 0)) {
        var bodyFormData = new FormData();
        bodyFormData.append("token", this.token);
        bodyFormData.append("user_id", this.user_id);
        bodyFormData.append("opt", "update");
        bodyFormData.append("id", this.cur_floor.id);
        bodyFormData.append("store_id", this.cur_store_id);
        bodyFormData.append("name", this.cur_floor.name);
        if (this.file1) {
          bodyFormData.append("file1", this.file1);
        }
        if (this.cur_floor.use_image) {
          bodyFormData.append("use_image", 1);
        } else {
          bodyFormData.append("use_image", 0);
        }
        bodyFormData.append("width", 1024);
        bodyFormData.append("height", 640);
        if (this.cur_floor.status) {
          bodyFormData.append("status", 1);
        } else {
          bodyFormData.append("status", 0);
        }

        var that = this;
        axios({
          method: "post",
          url: "/Api/Web/Floor/edit",
          data: bodyFormData,
        })
          .then(function (response) {
            if (response.status == "200") {
              var rt = response.data;
              if (rt.status == 0) {
                // that.$router.push("/store");
                // that.$router.go();
                if (rt.data.floors) {
                  that.floorlist = rt.data.floors;
                }
                if (rt.data.floor.status == 1) {
                  rt.data.floor.status = true;
                } else {
                  rt.data.floor.status = false;
                }
                if (rt.data.floor.use_image == 1) {
                  rt.data.floor.use_image = true;
                } else {
                  rt.data.floor.use_image = false;
                }
                that.cur_floor = rt.data.floor;
              } else if (rt.message != undefined) {
                alert(rt.message);
              }
            }
          })
          .catch(function (error) {
            // console.log(error);
            that.$router.push("/weblogin");
          });
      }
    },
    allowDrop(ev) {
      ev.preventDefault();
    },
    drag(ev, tb) {
      this.movingevt = ev;
      this.movingtable = tb;
      this.movingleft = ev.screenX;
      this.movingtop = ev.screenY;
    },
    drop(ev) {
      ev.preventDefault();
      var offx = ev.screenX - this.movingleft;
      var offy = ev.screenY - this.movingtop;

      var nowx = parseInt(this.movingevt.target.style.left) || 0;
      var nowy = parseInt(this.movingevt.target.style.top) || 0;

      var newx = nowx + offx;
      var newy = nowy + offy;
      if (newx < 0) newx = 0;
      if (newy < 0) newy = 0;
      if (newx + 60 > 1024) newx = 1024 - 60;
      if (newy + 60 > 640) newy = 640 - 60;

      this.movingevt.target.style.left = newx + "px";
      this.movingevt.target.style.top = newy + "px";
      this.movingtable.offset_x = newx;
      this.movingtable.offset_y = newy;
      this.update_table_xy(this.movingtable);
    },
    async removetable(ev, tb) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", "disable");
      bodyFormData.append("id", tb.id);
      bodyFormData.append("status", 2);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Table/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              // that.$router.push("/store");
              // that.$router.go();
              that.tablelist.forEach((item, idx, obj) => {
                if (item.id == tb.id) {
                  obj.splice(idx, 1);
                }
              });
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/weblogin");
        });
    },
    new_table_type() {
      if (this.cur_table.shape == "H-Rectangle") {
        this.tabledivclass = "h";
        this.tablespanclass = "sh";
      } else if (this.cur_table.shape == "W-Rectangle") {
        this.tabledivclass = "w";
        this.tablespanclass = "sw";
      } else if (this.cur_table.shape == "Round") {
        this.tabledivclass = "r";
        this.tablespanclass = "sr";
      } else {
        this.tabledivclass = "s";
        this.tablespanclass = "ss";
      }
    },
    async update_table_xy(tb){
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", "update");
      bodyFormData.append("id", tb.id);
      bodyFormData.append("offset_x", tb.offset_x);
      bodyFormData.append("offset_y", tb.offset_y);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Table/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              // that.$router.push("/store");
              // that.$router.go();
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/weblogin");
        });
    },
    async form_submit(event) {
      if (event) {
        event.preventDefault();
      }
      if (this.cur_table.name == '') {
        return;
      }
      if (this.cur_table.guest == '') {
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", "add");
      bodyFormData.append("store_id", this.cur_store_id);
      bodyFormData.append("floor_id", this.cur_floor.id);
      bodyFormData.append("name", this.cur_table.name);
      bodyFormData.append("shape", this.cur_table.shape);
      bodyFormData.append("ttype", this.cur_table.ttype);
      bodyFormData.append("guest", this.cur_table.guest);
      bodyFormData.append("sort_order", 999);
      bodyFormData.append("offset_x", 0);
      bodyFormData.append("offset_y", 0);
      bodyFormData.append("status", 1);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Table/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              // that.$router.push("/store");
              // that.$router.go();
              that.cur_table = that.new_table;
              that.get_cur_table_list();
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/weblogin");
        });
    },
    async get_cur_table_list() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("store_id", this.cur_store_id);
      bodyFormData.append("floor_id", this.cur_floor.id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Table/search",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.tablelist = rt.data.tables;
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            this.$router.push("/weblogin");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/weblogin");
        });
    },
    async get_table_list() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("store_id", this.search.store_id);
      bodyFormData.append("Floor", this.search.floor_id);
      bodyFormData.append("status", this.search.status);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Table",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (rt.data.stores.length <= 0) {
                that.$router.push("/store");
                return;
              }
              that.cur_store_id = rt.data.stores[0].id;
              that.storelist = rt.data.stores;
              that.floorlist = rt.data.floors;
              if (that.floorlist.length > 0) {
                if (that.floorlist[0].status == 1) {
                  that.floorlist[0].status = true;
                } else {
                  that.floorlist[0].status = false;
                }
                if (that.floorlist[0].use_image == 1) {
                  that.floorlist[0].use_image = true;
                } else {
                  that.floorlist[0].use_image = false;
                }
                that.cur_floor = that.floorlist[0];
                that.table_shapes = rt.data.table_shapes;
                that.table_types = rt.data.table_types;
                that.table_status = rt.data.table_status;
                that.tablelist = rt.data.tables;
              }
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/weblogin");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/weblogin");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/weblogin");
        return;
      }
      return this.get_table_list();
    },
  },
  mounted() {
    this.init_data();
    this.windowheight = window.innerheight;
  },
  computed: {
    tablelistimage() {
      if (this.cur_floor.image == '' || !this.cur_floor.use_image) {
        return {};
      } else {
        var imgurl = axios.defaults.baseURL + '/Api' + this.cur_floor.image;
        return {'backgroundImage': `url(${imgurl})`, 'background-size': '1024px 640px', 'background-repeat': 'no-repeat', 'background-position': 'center center' };
      }
    },
  },
};
</script>
<style>
.new-table-img {
  height: 100px;
}
.tablelistdiv {
  position: relative;
  width: 1024px;
  height: 640px;
  border-style: double;
}
.table-margin-top {
  margin-top: 1em;
}
.save-all-margin-top {
  margin-top: 100px;
}
.table-content {
  padding-top: 30px !important;
}
.form-group-bottom-margin {
  margin-bottom: 0em;
}
.s {
  position: absolute;
  background-color: #c0c0c0;
  text-align: center;
  font-size: 20px;
  width: 60px;
  height: 60px;
  border: solid 1px black;
  border-radius: 5px;
}
.ss {
  display: inline-block;
  margin-top: 14px;
}
.w {
  position: absolute;
  background-color: #c0c0c0;
  text-align: center;
  font-size: 20px;
  width: 80px;
  height: 60px;
  border: solid 1px black;
  border-radius: 5px;
}
.sw {
  display: inline-block;
  margin-top: 14px;
}
.h {
  position: absolute;
  background-color: #c0c0c0;
  text-align: center;
  font-size: 20px;
  width: 60px;
  height: 80px;
  border: solid 1px black;
  border-radius: 5px;
}
.sh {
  display: inline-block;
  margin-top: 24px;
}
.r {
  position: absolute;
  background-color: #c0c0c0;
  text-align: center;
  font-size: 20px;
  width: 70px;
  height: 70px;
  border: solid 1px black;
  border-radius: 50%;
}
.sr {
  display: inline-block;
  margin-top: 18px;
}
.content {
  padding-top: 80px;
}
</style>
